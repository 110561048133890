.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.anchor {
  float: left;
  font-size: small;
  color: blue;
  margin-bottom: 4px;
  margin-top: 2px;
}

.imgwidthHeightAbout {
  width: 344px;
  height: 318px;
}

.para {
  margin-bottom: 0px;
  height: 120px;
  overflow: hidden;
}

.datanotFound {
  margin-bottom: 3%;
}

.blogMargin {
  margin: 1%;
}

.leftAligh {
  text-align: left;
}

a {
  color: #00a2ed;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.pcontainer {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-template-rows: repeat(2, 100px);
}

.partnershipCon {
  width: 60%;
  margin-left: 20%;
  margin-top: 45px;
}

.clsPartnershipLogo {
  width: 80%;
  margin-left: 10%;
}

.pcontainer>div>a>img {
  width: 100%;
  height: 100%;
}

.btnPrimary {
  color: #fff;
  background-color: #3ca59d;
  border-color: #3ca59d;
}

.backgroundcolorbtn {
  background-color: #007bff
}

.backgroundcolorbtn:hover {
  background-color: #007bff
}

.spnError {
  float: left;
  color: #cd1b1b;
}

.clscontact {
  text-align: left;
}

.partnershipP {
  padding: 16px;
  text-align: center;
  width: 80%;
  margin-left: 9%;
}

.clsIcone {
  position: absolute;
  margin-left: 215px;
}

.partnershipImage {
  width: 40%;
  height: 40%;
  margin-top: 5%;
  margin-left: 30%;
}

.partnershipP img {
  margin: 4%;
}

.likebutton {
  position: absolute;
  right: 12%;
  top: 5%;
}

.ant-modal-content {
  height: 100%;
}

.ant-modal-body {
  margin-top: 2%;
  width: 95%;
}

.likebuttonAll {
  position: relative;
  float: right;
  top: 19px;
}

.top5 {
  margin-top: 3%;
}

.top10 {
  margin-top: 7%;
}

.spanPersonalities {
  text-align: center;
  position: relative;
  left: 45%;
}

.ddldiv {
  position: relative;
  padding: 10px;
  bottom: 53px;
}

.podcastDiv1 {
  display: grid;
  align-items: center;
  gap: 2px;
  grid-template-columns: repeat(auto-fit, minmax(min(15rem, 100%), 1fr));
}

.podcastDivall {
  display: grid;
  align-items: center;
  gap: 3px;
  grid-template-columns: repeat(auto-fit, minmax(min(20rem, 100%), 1fr));
}

.podcastAll:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}


.podcastDiv1news {
  display: grid;
  align-items: center;
  gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(min(20rem, 100%), 1fr));

}

.whitebackground {
  background-color: white !important;
}

.clsicone {
  position: relative;
  left: 208px;
  top: -81px;
}

.divnewPartner {
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s; */
  border: 1px solid rgba(180, 176, 176, 0.2);
  ;
  margin: 3px;
  width: 250px;
  height: 250px;
}

.divnewPartner img {
  margin-top: 14%;
  height: 70%;
  width: 80%;

}

.divnewPartner:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.4);
}

.podcastAll {
  display: block;
  /* margin: 2%; */
}

.clsDivSection {
  margin-bottom: 30px;
  text-align: center;
  width: 100%;
  /* background-color: rgba(222, 217, 217, 0.1); */
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s; */
  height: 43vh;
  margin-top: 10px;
  padding-top: 15px;
}

.topPod2 {
  margin-top: 43px;
}

.topBehind {
  margin-top: 30px;
}

.clsSocialDiv {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.reducePadding {
  padding-left: 8px !important;
  padding-right: 8px !important;
}

.clsSocialDiv a {
  margin-left: 15px;
}

.clsSocialDivtext {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.clsSocialDivtext a {
  margin-left: 2%;
  margin-top: 6%;
  color: #131414;
}


.imgEdit {
  position: relative;
  left: 180px;
  top: -324px;
}

.dataAction {
  display: grid;
}

.dataAction button {
  margin-bottom: 4px;
  border: 1px solid gainsboro;
}

.audioEdit {
  position: relative;
  left: 75px;
  top: -180px;
}

.rowinsertForm {
  margin: 2%;
  padding: 2px;
}

.containerinserPage {
  width: 80%;
}

/* .containerinserPage span {
 font-weight: 100; 
} */

.ddlinsert {
  width: 100%;
}

.clsbtnInsert {
  margin-bottom: 5%;
}

.clsisDropDownShow {
  margin-bottom: 5%;
}

.clsBannerUpdate {
  width: 70%;
  margin-bottom: 5%;
}

.clsbtnBanner {
  margin: 2%;
}

.tblBanner {
  width: 90%;
  text-align: center;
  margin-left: 5%;
}

.wrapCss {
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 70%;
  row-gap: 0px;
  column-gap: 0px;
  margin-top: 3%;
}

.wrapCssBlog {
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 78%;
  row-gap: 0px;
  column-gap: 0px;
  margin-top: 0%;
}

#wrapCssBlog::after {
  content: "\a";
  white-space: pre;
}

.spnDatePod {
  position: absolute;
  left: 15%;
  font-size: small;
  color: aliceblue;
  font-weight: bold;
}

.spnAuthor {
  font-size: small;
  position: absolute;
  left: 30%;
  color: #131414;
}

.clsImageSize {
  width: 200px;
  height: 250px;
}

.clsImageSizeAll {
  width: 250px;
  height: 250px;
}

.clsImageSizeAll1 {
  width: 259px;
  height: 250px;
}

.paddingTopcls {
  padding-top: 5px !important;
}

.dateForAllEpi {
  font-size: small;
  position: absolute;
  color: white;
  font-weight: bold;
}

.authorSpanAllEpiOnlyOne {
  font-size: small;
  color: rgb(14, 13, 13);
  font-weight: 200;
  display: block;
}

.authorSpanAllEpi {
  font-size: small;
  color: rgb(14, 13, 13);
  font-weight: 200;
  display: block;
}

.singleBlogImage {
  width: 400px;
  height: 400px;
}

.spndateNew {
  margin-right: 1%;
}

.rowshowData {
  width: 80%;
  text-align: center;
  margin: 2%;
}

.topspace {
  margin-top: 5%;
}

.imgHeightWidth {
  height: 250px !important;
  width: 100%
}

.top61px {
  top: -61px;
}

.wrapCss button {
  padding: 1px;
}

.top10pix {
  margin-top: 28px;
  display: block;
  margin-bottom: 20px;
}

.clsMoses {
  position: relative;
  top: -18px;
  font-weight: bold;
}

.clsHomeSection {
  width: 67%;
  margin-left: 16%;
  color: rgb(77, 77, 77);
  font-size: 19.6px;
  font-weight: 200;
}

.clsAboutUsText {
  color: rgb(77, 77, 77);
  font-size: 19.6px;
  font-weight: 200;
  text-align: justify;
  margin-top: 30px;
}

.clsAboutUsTextHost {
  color: rgb(77, 77, 77);
  font-size: 19.6px;
  font-weight: 200;
  text-align: start;
  margin-top: 5px;
}

.clshostTextLeft {
  margin-left: 4px;
}

.clsImageSocial {
  width: 50px;
  height: 50px;
}

.clshostTextRight {
  margin-right: 4px;
}

.imgAboutUsMain {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  margin: 1px;
  margin-bottom: 30px;
  display: inline-table;
  position: relative;
  width: 355px;
  height: 350px;

}

.imgAboutCoin {
  display: flex;
  text-align: center;
  margin-top: 30px;
}


.imgAboutUsMain:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.4);
}

.clsAboutUsTextImg {
  color: rgb(77, 77, 77);
  font-size: 19.6px;
  font-weight: 200;
  text-align: center;
}

.clsUpdateTextAndImage {
  text-align: center;
  margin-bottom: 40px;
}

.clsModel {
  position: relative;
  margin-top: -10px;
}

.searchData {
  margin-left: 2px;
  overflow: auto;
  height: 500px;
}

.searchDataCard {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
  transition: 0.1s;
  margin-top: 5px;
  cursor: pointer;
}
.clsBlog{
  margin-left: 10%;
  margin-right: 10%;
  margin-bottom: 5%;
  border: 0px !important;
}
.clsCardAlign{
  text-align: justify;
}
.clsTitle {
  font-size: large;
  font-weight: 300;
  font-style: normal;
}

.clssubDiv {
  font-size: small;
  font-weight: 200;
  font-style: normal;
}

.searchDataCard:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.4);
}

.clsimgSmall {
  width: 80px;
  height: 80px;
}

.spinSearch {

  top: 50px !important;
}

.paddingBottam {
  padding-bottom: 8px !important;
}

.marginLeftRight {
  margin-left: 0px !important;
  margin-right: 0px !important;
  width: 100% !important;
}
.clsblogPostTitle{
  height: 78px;
  overflow:hidden
}
.clsblogPostText{
  height: 167px;
  overflow:hidden
}

.clsSubcribeStyle {
  background-image: url(/public/assest/images/hero_bg_1.jpg);
}
.card-text p{
  text-align: left;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}